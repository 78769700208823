import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Notification {
  id: string;
  heading: string;
  subHeading: string;
  creationTime: string;
  sendTime: string;
  isSent: boolean;
  advertId: string;
}

const PassNotifications = () => {
  const { adId } = useParams();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`/get-pass-notifications/${adId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        setNotifications(response.data);
      } catch (err) {
        console.error('Error fetching notifications:', err);
        setError('Failed to load notifications. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [adId]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen w-3/4">
      <div className="max-w-xl mx-auto bg-white p-4 rounded-lg shadow">
        <button
          type="button"
          className="text-gray-600 flex items-center mb-4"
          onClick={() => window.history.back()}
        >
          ← Notifications
        </button>
        <h2 className="text-xl font-semibold mb-4">Notifications</h2>
        <h4>{adId}</h4>

        {loading ? (
          <p className="text-center text-gray-500">Loading notifications...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : notifications.length === 0 ? (
          <p className="text-center text-gray-500">No notifications found.</p>
        ) : (
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-[#494949] text-white text-left">
                  <th className="py-2 px-4">Headline</th>
                  <th className="py-2 px-4">Sub-heading</th>
                  <th className="py-2 px-4">Sent</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map(notification => (
                  <tr key={notification.id} className="border-t">
                    <td className="py-2 px-4">{notification.heading}</td>
                    <td className="py-2 px-4 whitespace-pre-line">
                      {notification.subHeading.replace(/\\n/g, '\n')}
                    </td>
                    <td className="py-2 px-4">
                      {new Date(notification.sendTime).toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PassNotifications;
