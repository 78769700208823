import { IconButton, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import QRCode from 'qrcode.react';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMoreVert } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { BrandAd } from '../../../api';
import Image3 from '../../../assets/shared/Gift_QR_Dark.png';
import Image2 from '../../../assets/shared/Gift_QR_Preferred.png';
import Image4 from '../../../assets/shared/Simple_QR_Dark@2x.png';
import Image5 from '../../../assets/shared/Simple_QR_Preferred.png';
import CreateNotificationModal from '../../../pages/Notifications/CreateNotificationModal';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import StatusTag from '../../UI/StatusTag/StatusTag';

const imagesQR = [
  {
    id: 2,
    url: Image2,
    width: '191px',
    height: '99px',
    top: 120,
    left: -11,
    fgColor: 'black',
  },
  {
    id: 3,
    url: Image3,
    width: '191px',
    height: '99px',
    top: 120,
    left: -11,
    fgColor: '#0D172F',
  },
  {
    id: 4,
    url: Image4,
    width: '195px',
    height: '140px',
    top: 22,
    left: -12.3,
    fgColor: '#0D172F',
  },
  {
    id: 4,
    url: Image5,
    width: '195px',
    height: '140px',
    top: 22,
    left: -12.3,
    fgColor: '#0D172F',
  },
];

interface Props {
  item: BrandAd;
  adId: string;
  brandId: any;
  onClick: () => void;
  index: number;
  onDownload: (adId: string) => void;
}

const ListAds: FC<Props> = ({ item, adId, brandId, index, onClick, onDownload }) => {
  const { t } = useTranslation('ads');
  const qrCodeReference: any = useRef([]);
  const [shortDynamicLink, setShortDynamicLink] = useState('');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const zip = new JSZip();
  const base64Files: { name: string; base64: string }[] = [];
  const base64PNGFiles: { name: string; base64: string }[] = [];
  const identityKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const magicLink = `${process.env.REACT_APP_DYNAMIC_LINK_HOST}/?link=${process.env.REACT_APP_DYNAMIC_LINK_HOST}/tickleapp/savecontent?id%3D${adId}%26source%3DTickle%26brandid%3D${brandId}&isi=${process.env.REACT_APP_APPLE_APP_STORE_ID}&ibi=${process.env.REACT_APP_IOS_BUNDLE_ID}`;

  const firebaseurlLink = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks';

  const handleMenuOpen = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  const handleCreateNotification = () => {
    setOpenModal(true);
    handleMenuClose();
  };

  const handleViewSentNotifications = () => {
    navigate(`/pass-notifications/${selectedId}`);
    handleMenuClose();
  };

  const generateShortUrl = async () => {
    if (!shortDynamicLink) {
      const shortLink = await axios.post(`${firebaseurlLink}?key=${identityKey}`, {
        longDynamicLink: magicLink,
        suffix: {
          option: 'UNGUESSABLE',
        },
      });
      await setShortDynamicLink(shortLink.data?.shortLink);
    }
  };

  const downloadQRSVG = () => {
    try {
      qrCodeReference.current.forEach(async (element: any, i: number) => {
        element.style.display = 'block';

        domtoimage
          .toSvg(element)
          .then((dataUrl: any) => {
            base64Files.push({ name: `QRCode-${i + 1}.svg`, base64: dataUrl });
            setTimeout(() => {
              element.style.display = 'none';
            }, 1000);
          })
          .catch((error: any) => {
            element.style.display = 'none';
          });
      });

      setTimeout(async () => {
        base64Files.forEach((s, indx) => {
          const url = s?.base64?.split('charset=utf-8,')[1];
          zip.file(`QRCode_${indx + 1}.svg`, url, { binary: true });
        });
      }, 1000);
      console.log('succesful svg');
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const downloadQRPNG = async (items: any) => {
    try {
      qrCodeReference.current.forEach(async (element: any, i: number) => {
        element.style.display = 'block';
        const scale = 2;
        const options = {
          width: element.clientWidth * scale,
          height: element.clientHeight * scale,
          style: {
            transform: `scale( ${scale} )`,
            transformOrigin: 'top left',
          },
        };
        domtoimage
          .toPng(element, options)
          .then((dataUrl: any) => {
            base64PNGFiles.push({ name: `QRCode-${i + 1}.png`, base64: dataUrl });
            setTimeout(() => {
              element.style.display = 'none';
            }, 1500);
          })
          .catch((error: any) => {
            element.style.display = 'none';
          });
        setTimeout(async () => {
          base64PNGFiles.forEach((s, indx) => {
            const urlPng = s?.base64?.split('base64,')[1];
            zip.file(`QRCode_${indx + 1}.png`, urlPng, { base64: true });
          });

          const zipContent = await zip.generateAsync({ type: 'blob' });
          saveAs(zipContent, `QRCode_${item?.title?.split(' ')?.join('_')}-Tickle.zip`);
        }, 1500);
      });
    } catch (error) {
      console.log();
    }
  };

  const downloadQRCode = async (element: any) => {
    try {
      downloadQRSVG();
      downloadQRPNG(element);
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    const app = document.getElementById('qrcode-add');
    if (app) {
      app.style.display = 'none';
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (!shortDynamicLink) {
        generateShortUrl();
      }
    }, 100);
  }, [adId]);

  return (
    <div>
      <div
        data-testid="brand-ad"
        className={clsx(
          'p-4 flex items-center w-full justify-between',
          index > 0 && 'border-t-[1px] border-lightGrey'
        )}
      >
        <div className="flex items-center justify-center gap-5">
          <img src={item.imageUrl} alt="Ad" className="w-40 rounded-lg" />
          <button type="button" onClick={onClick}>
            <p className="text-[1.125rem] hover:underline cursor-pointer">{item.title}</p>
          </button>
        </div>
        <div className="flex gap-6 items-center">
          <StatusTag state={item.state} />

          <Button type="text" size="small" onClick={() => onDownload(item.id)}>
            {t('button.download')}
          </Button>

          <Button type="text" size="small" onClick={() => downloadQRCode(adId)}>
            {t('button.download-qr')}
          </Button>
          <IconButton onClick={e => handleMenuOpen(e, item.id)}>
            <MdMoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedId === item.id}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleCreateNotification}>Create notification</MenuItem>
            <MenuItem onClick={handleViewSentNotifications}>
              View sent notifications
            </MenuItem>
          </Menu>
          <Icon name="chevron" width="24px" height="24px" />
        </div>
      </div>
      {/* , top: '-9999px'  , display: 'none' */}
      <div style={{ position: 'absolute', top: '-9999px' }}>
        <div className="flex flex-row w-full flex-wrap">
          {imagesQR?.map((qrCodeDetails, i) => (
            <div className="pl-4" key={i}>
              <div
                ref={el => {
                  qrCodeReference.current[i] = el;
                }}
                style={{ position: 'relative', display: 'none' }}
              >
                <img
                  src={qrCodeDetails?.url}
                  alt="Background"
                  style={{ width: '170px', height: '230px' }}
                />
                <QRCode
                  style={{
                    width: qrCodeDetails?.width,
                    height: qrCodeDetails?.height,
                    top: qrCodeDetails?.top,
                    left: qrCodeDetails?.left,
                    position: 'absolute',
                    borderRadius: '5px',
                  }}
                  fgColor={qrCodeDetails.fgColor}
                  value={shortDynamicLink}
                  renderAs="svg"
                />
              </div>
            </div>
          ))}{' '}
        </div>
      </div>
      <CreateNotificationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        adId={item.id}
      />
    </div>
  );
};

export default ListAds;
