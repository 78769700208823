import axios from 'axios';
import clsx from 'clsx';
import { ChangeEvent, FC, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';

interface CreateNotificationModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  adId: string;
}

const CreateNotificationModal: FC<CreateNotificationModalProps> = ({
  openModal,
  setOpenModal,
  adId,
}) => {
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [errors, setErrors] = useState({ heading: '', subHeading: '' });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const validateForm = () => {
    let valid = true;
    const newErrors = { heading: '', subHeading: '' };

    if (!heading.trim()) {
      newErrors.heading = 'Heading is required.';
      valid = false;
    }

    if (!subHeading.trim()) {
      newErrors.subHeading = 'Sub-heading is required.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSendNotification = async () => {
    if (!validateForm()) return;

    setLoading(true);
    setErrors({ heading: '', subHeading: '' });
    setSuccessMessage('');

    const payload = {
      adId,
      heading,
      subHeading,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/pass-notifications`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            cors: 'no-cors',
          },
        }
      );
      console.log('Notification sent successfully:', response.data);
      setSuccessMessage('Notification sent successfully!');
      setTimeout(() => setOpenModal(false), 1500);
    } catch (error) {
      console.error('Error sending notification:', error);
      setErrors({ ...errors, subHeading: 'Failed to send notification. Try again.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal type="small" height="h-[400px]" isOpen={openModal} setIsOpen={setOpenModal}>
      <div className="h-full flex flex-col justify-between">
        <div>
          <h2 className="text-[20px] font-semibold mb-3">Send Notification</h2>

          <p className="my-2 text-base font-medium">Heading:</p>
          <input
            type="text"
            className={clsx('input w-full', { 'border-red-500': errors.heading })}
            value={heading}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setHeading(e.target.value)}
            disabled={loading}
          />
          {errors.heading && <p className="text-red-500 text-sm">{errors.heading}</p>}

          <p className="mt-3">Sub-Heading:</p>
          <textarea
            className={clsx('input w-full', { 'border-red-500': errors.subHeading })}
            value={subHeading}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setSubHeading(e.target.value)
            }
            rows={6}
            maxLength={400}
            disabled={loading}
          />
          {errors.subHeading && (
            <p className="text-red-500 text-sm">{errors.subHeading}</p>
          )}
        </div>

        {successMessage && (
          <p className="text-green-500 text-sm mt-2">{successMessage}</p>
        )}

        <div className="flex justify-between">
          <Button type="text" onClick={() => setOpenModal(false)} disabled={loading}>
            Cancel
          </Button>
          <Button type="secondary" onClick={handleSendNotification} disabled={loading}>
            {loading ? 'Sending...' : 'Send Notification'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNotificationModal;
