import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { AdFormSchema } from '../AdForm/AdFormTypes';

interface GoogleLogoImageProps {
  defaultImage?: string;
  width?: number;
  height?: number;
}

const GoogleLogoFormImage = ({ defaultImage, width, height }: GoogleLogoImageProps) => {
  const {
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext<AdFormSchema>();

  return (
    <div
      className={clsx(
        'bg-white rounded-md h-fit p-4',
        errors.passDetails?.googlePassLogo?.message && 'border-error'
      )}
      data-testid="image-input"
    >
      {/* <h2 className="text-lg font-semibold">{t('form.ad-image')}</h2> */}
      <Controller
        control={control}
        name="passDetails.googlePassLogo"
        rules={{ required: 'Ad image is required.' }}
        render={({ field: { onChange } }) => (
          <ImageUploader
            subText="or drop an image to upload"
            criteria={`Image size ${width} x ${height} px (.jpg .png or .webp). Maximum 5MB.`}
            widthReq={width ?? 900}
            heightReq={height ?? 750}
            defaultImg={defaultImage || ''}
            imageUrlCB={onChange}
            setErrorAdvert={error => setError('passDetails.googlePassLogo', error)}
            clearErrorsAdvert={clearErrors}
            imgType="GOOGLE_WALLET_LOGO"
            formError={errors.passDetails?.googlePassLogo?.message ?? ''}
          />
        )}
      />
    </div>
  );
};

export default GoogleLogoFormImage;
