import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CodeUploadResponse, OfferResponse } from '../../api';
import AdForm from '../../components/Forms/AdForm/AdForm';
import { AdFormSchema } from '../../components/Forms/AdForm/AdFormTypes';
import { OfferFormType } from '../../components/Forms/OfferForm/OfferForm';
import Icon from '../../components/UI/Icon/Icon';
import Loader from '../../components/UI/Loader/Loader';
import { BrandContext } from '../../context/BrandContext';
import useAdById from '../../services/queries/useAdById';
import useBrandsById from '../../services/queries/useBrandById';
import useEditAd from '../../services/queries/useEditAd';
import usePostUploadCsv from '../../services/queries/usePostUploadCsv';

const EditAd: FC = () => {
  const { t } = useTranslation('ads');
  const nav = useNavigate();
  const [categoriesArr, setCategoriesArr] = useState<any>([]);
  const [categoriesChildArr, setCategoriesChildArr] = useState<any>([]);
  const [advertError, setAdvertError] = useState<any>('');
  const [countryList, setCountryList] = useState<any>([]);

  const { brandId, adId } = useParams();
  const { mutateAsync: uploadCsvCodes, isLoading: isUploadCsvLoading } =
    usePostUploadCsv(brandId);
  const [adIsActive, setAdIsActive] = useState(false);
  const { setBrand } = useContext(BrandContext);

  const { mutate: editAd, isLoading: editLoading } = useEditAd(brandId, adId);
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  const { data: ad, isLoading: adIsLoading } = useAdById(brandId, adId);

  useEffect(() => {
    if (ad) {
      const now = new Date();
      const start = ad.startTime ? new Date(ad.startTime) : now;
      if (start < now) {
        setAdIsActive(true);
      }
    }
  }, [ad]);

  const formatDate = (datetime: string) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDateReverse = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };

  const initialFormData = useMemo(() => {
    if (ad === undefined) return undefined;

    const data: AdFormSchema = {
      ...ad,
      id: ad.id,
      brandId: ad.brandId,
      title: ad.title,
      categories: ad.categories,
      googleAdId: ad.googleAdId,
      beginDate: ad.startTime?.split('T')[0] || '',
      beginTime: ad.startTime?.split('T')[1].slice(0, -4) || '',
      endDate: ad.expiryTime?.split('T')[0] || '',
      endTime: ad.expiryTime?.split('T')[1].slice(0, -4) || '',
      imagePath: ad.imagePath,
      imageUrl: ad.imageUrl,
      followedCategories: ad.followedCategories,
      followedParentCategories: ad.followedParentCategories,
      followedChildCategories: ad.followedChildCategories,
      passDetails: {
        ...ad.passDetails,
        promotions: ad?.passDetails?.promotions.map(x => {
          return { ...x, expiryDate: formatDate(x?.expiryDate) };
        }),
      },
      offers:
        ad.offers?.map(offer => ({
          ...offer,
          beginDate: offer?.startTime?.split('T')[0] || '',
          beginTime: offer?.startTime?.split('T')[1].slice(0, -4) || '',
          endDate: offer?.expiryTime?.split('T')[0] || '',
          endTime: offer?.expiryTime?.split('T')[1].slice(0, -4) || '',
          boosts: offer.boosts?.map(boost => ({
            ...boost,
            id: boost.id || '',
            boostAvailable: true,
            isUltimate: boost.isUltimate,
          })),
          offerCodes: offer.offerCodes,
        })) || [],
    };

    return data;
  }, [ad]);

  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
  }, []);

  useEffect(() => {
    document.title = brand ? brand?.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const getUploadCSVPromises = (
    formOffers: OfferFormType[],
    responseOffers: OfferResponse[]
  ) => {
    const csvPromises: Array<Promise<CodeUploadResponse>> = [];
    formOffers?.forEach((offer, index) => {
      const requestTextCheck = `${offer.title}${offer.subtitle}`;
      const responseTextCheck = `${responseOffers?.[index].title}${responseOffers?.[index].subtitle}`;
      const matchedOfferId =
        requestTextCheck === responseTextCheck && offer.id
          ? offer.id
          : responseOffers?.find(o => `${o.title}${o.subtitle}` === requestTextCheck)?.id;
      if (offer.csvFile) {
        csvPromises.push(
          uploadCsvCodes({
            offerId: matchedOfferId || '',
            csv: offer.csvFile,
            mode: 'APPEND',
          })
        );
      }
      offer.boosts?.forEach(boost => {
        if (boost.csvFile) {
          csvPromises.push(
            uploadCsvCodes({
              offerId: matchedOfferId || '',
              boostId: boost.id,
              csv: boost.csvFile,
              mode: 'APPEND',
            })
          );
        }
      });
    });

    return csvPromises;
  };

  const onSubmit: SubmitHandler<AdFormSchema> = async data => {
    // if (!countryList?.length && data.offers?.length) {
    //   setAdvertError("Please select atleast one country");
    // }
    // else
    if (!categoriesArr?.length) {
      setAdvertError('Please select atleast one category');
    }
    // else if (!categoriesChildArr?.length) {
    //   setAdvertError("Please select atleast one subcategory");
    // }
    else {
      console.log('Free countryList', countryList);
      editAd(
        {
          title: data.title,
          googleAdId: data.googleAdId,
          imagePath: data.imagePath,
          startTime: `${data.beginDate}T${data.beginTime}:00Z`,
          expiryTime: `${data.endDate}T${data.endTime}:00Z`,
          categories: [...categoriesArr, ...categoriesChildArr],
          offers: data.offers
            ? data.offers.map(offer => ({
                id: offer.id,
                title: offer.title,
                requiredCoins: Number(offer.requiredCoins),
                enableWeightage: offer.enableWeightage,
                enableHalo: offer.enableHalo,
                subtitle: offer.subtitle,
                description: offer.description,
                imagePath: offer.imagePath,
                redemptionType: offer.redemptionType,
                offerStatus: offer.offerStatus,
                countries: offer.countries ?? [],
                redemptionUrl: offer.redemptionUrl,
                startTime: `${offer.beginDate}T${offer.beginTime}:00Z`,
                expiryTime: `${offer.endDate}T${offer.endTime}:00Z`,
                boosts: offer.boosts?.map(boost => ({
                  id: boost.id,
                  title: boost.title,
                  subtitle: boost.subtitle,
                  type: boost.type,
                  weightage: boost.weightage ? Number(boost.weightage) : 0,
                  quantity: boost.quantity ? Number(boost.quantity) : 0,
                  description: boost.description,
                  imagePath: boost.imagePath,
                  redemptionType: boost.redemptionType,
                  redemptionUrl: boost.redemptionUrl,
                  boostAvailable: boost.boostAvailable,
                  halo: boost.halo,
                  isUltimate: boost.isUltimate,
                })),
              }))
            : [],
          wrapperDetails: {
            ...data.wrapperDetails,
            wrapperType: data.wrapperDetails?.wrapperType ?? 'DEFAULT',
          },
          passDetails: {
            ...data.passDetails,
            appStoreId: Number.parseInt(data.passDetails.appStoreId?.toString(), 10),
            promotions: data.passDetails?.promotions?.map(x => {
              const promotion = {
                ...x,
                isIncluded: x.isIncluded ?? false,
              };

              if (x?.expiryDate) {
                promotion.expiryDate = formatDateReverse(x.expiryDate);
              }

              return promotion;
            }),
          },
        },
        {
          onSuccess: response => {
            if (response.offers && data.offers) {
              Promise.all(getUploadCSVPromises(data.offers, response.offers))
                .then(() => {
                  nav(`/ads/${brandId}`, { replace: true });
                  window.location.reload();
                })
                .catch(error => {
                  console.log(error);
                });
            } else {
              nav(`/ads/${brandId}`, { replace: true });
            }
            setAdvertError('');
          },
          onError: (err: any) => {
            setAdvertError(err?.response?.data?.message);
          },
        }
      );
    }
  };

  if (adIsLoading || ad === undefined) {
    return <Loader />;
  }

  return (
    <div className="w-full wrapper mb-40 px-[100px]">
      <div className="flex w-full justify-start">
        <button
          type="button"
          onClick={() => {
            nav(`/ads/${brandId}`, { replace: true });
            window.location.reload();
          }}
          aria-label="go back"
          data-testid="back-button"
        >
          <Icon name="back-arrow" width="24" />
        </button>
        <h1 className="font-semibold">{t('form.edit')}</h1>
      </div>
      <AdForm
        adIsActive={adIsActive}
        onSubmit={onSubmit}
        backCountry={
          (e: any) => console.log('')
          // setCountryList(e?.map((es: any) => es.code))
        }
        isSubmitting={editLoading || isUploadCsvLoading}
        initialData={initialFormData}
        advertError={advertError}
        categoriesCheckArr={categoriesArr}
        categoryChildBackArr={(item: any) => setCategoriesChildArr(item)}
        categoryBackArr={(item: any) => {
          setCategoriesArr(item);
        }}
      />
    </div>
  );
};

export default EditAd;
