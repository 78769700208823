import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { FileError, useDropzone } from 'react-dropzone';
import { ErrorOption, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import usePostUploadImage, {
  ImageUploadRequest,
} from '../../services/queries/usePostUploadImage';
import { AdFormSchema } from '../Forms/AdForm/AdFormTypes';
import { OfferFormType } from '../Forms/OfferForm/OfferForm';
import ProgressBar from '../ProgressBar/ProgressBar';
import Button from '../UI/Button/Button';
import Icon from '../UI/Icon/Icon';

interface Props {
  subText: string;
  criteria: string;
  widthReq: number;
  heightReq: number;
  imgType: ImageUploadRequest['type'];
  defaultImg: string | undefined;
  disabled?: boolean;
  logo?: boolean;
  formError?: string;
  imageUrlCB?: (response: string) => void;
  clearErrorsAdvert?: UseFormClearErrors<AdFormSchema>;
  clearErrorsOffer?: UseFormClearErrors<OfferFormType>;
  setErrorAdvert?: (error: ErrorOption) => void;
  setErrorOffer?: (error: ErrorOption) => void;
  backImageBase64?: any;
  isImageBase64?: boolean | any;
}

const ImageUploader: FC<Props> = ({
  subText,
  criteria,
  logo,
  widthReq,
  heightReq,
  disabled = false,
  defaultImg,
  imgType,
  formError,
  imageUrlCB,
  setErrorAdvert,
  clearErrorsAdvert,
  setErrorOffer,
  clearErrorsOffer,
  backImageBase64,
  isImageBase64,
}) => {
  const { t } = useTranslation('brand');
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [errors, setErrors] = useState<FileError>();
  const [imgUrl, setImgUrl] = useState<string | undefined>(defaultImg);
  const URL = window.URL || window.webkitURL;
  const { brandId } = useParams();

  const { mutate: uploadImage, isLoading: uploading } = usePostUploadImage(brandId);

  useEffect(() => {
    setImgUrl(defaultImg);
  }, [defaultImg]);

  const makeUrlValid = (url: string) => {
    if (url !== '' && url !== null && url.startsWith('https:')) {
      return `${process.env.REACT_APP_GOOGLE_BUCKET}/${imgUrl}`;
    }
    return url;
  };

  const getBase64 = (file: any, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      image: ['.jpeg', '.png', '.jpg'],
    },
    onDrop: (acceptedFile, fileRejections) => {
      fileRejections.forEach(fileItem => {
        fileItem.errors.forEach(err => {
          if (err.code === 'file-invalid-type') {
            setErrors({
              code: 'file-invalid-type',
              message: `Error: File needs to be jpg / jpeg / png.`,
            });
            setErrorAdvert?.({
              type: 'custom',
              message: `Advert image needs to be jpg / jpeg / png.`,
            });
            setErrorOffer?.({
              type: 'custom',
              message: `Offer image needs to be jpg / jpeg / png.`,
            });
          }
          if (err.code === 'file-too-large') {
            setErrors({
              code: 'file-size',
              message: `Error: File is larger than 5mb.`,
            });
            setErrorAdvert?.({
              type: 'custom',
              message: `Image is larger than 5mb.`,
            });
            setErrorOffer?.({
              type: 'custom',
              message: `Image is larger than 5mb.`,
            });
          }
        });
      });

      if (fileRejections.length > 0) return;

      const fileItem = acceptedFile[0];
      setErrors(undefined);
      const img = new Image();
      const objectUrl = URL.createObjectURL(fileItem);
      img.onload = () => {
        if (img.width !== widthReq || img.height !== heightReq) {
          setErrors({
            code: 'file-not-correct-size',
            message: `File needs to be ${widthReq}px x ${heightReq}px.`,
          });
          setImgUrl('');
          setErrorAdvert?.({
            type: 'custom',
            message: `Image needs to be ${widthReq}px x ${heightReq}px.`,
          });
          setErrorOffer?.({
            type: 'custom',
            message: `Image needs to be ${widthReq}px x ${heightReq}px.`,
          });

          backImageBase64({ fullBase64: '', imgBase64: '', imgType: '', imgName: '' });
        } else {
          let fileBase64 = '';
          getBase64(acceptedFile[0], (result: any) => {
            fileBase64 = result;
            backImageBase64({
              fullBase64: fileBase64,
              imgBase64: fileBase64.split(',')?.[1],
              imgType: acceptedFile[0]?.type,
              imgName: acceptedFile[0]?.name,
            });
          });

          if (!isImageBase64) {
            uploadImage(
              { image: acceptedFile[0], type: imgType },
              {
                onSuccess: response => {
                  setImgUrl(URL.createObjectURL(acceptedFile[0]));
                  if (imageUrlCB) {
                    imageUrlCB(response.imagePathCompressedAsWebp);
                    if (clearErrorsAdvert) {
                      clearErrorsAdvert('imagePath');
                    }
                    if (clearErrorsOffer) {
                      clearErrorsOffer('imagePath');
                    }
                  }
                },
                onError: err => {
                  setImgUrl('');
                  setErrors({
                    message: err.data,
                    code: 'Upload Failed.',
                  });
                },
              }
            );
          }
        }
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    },
  });

  return (
    <div
      className={clsx(
        'file-upload flex flex-col border-[1px] border-dashed rounded-md',
        (formError || errors) && 'bg-error-container border-[#C24F4F]',
        disabled && 'bg-lightGrey pointer-events-none'
      )}
      data-testid="file-upload"
    >
      <div
        {...getRootProps({ className: 'dropzone' })}
        className="flex flex-col items-center gap-3 py-8 w-full h-full"
      >
        <input {...getInputProps()} />

        {imgUrl ? (
          <>
            <img
              ref={imageRef}
              src={imgUrl}
              alt="uploaded preview"
              className={clsx(
                'max-w-[434px] h-full object-cover',
                logo && 'rounded-full w-[92px] h-[92px] object-fill'
              )}
              onLoad={() => {
                if (imgUrl) {
                  URL.revokeObjectURL(imgUrl);
                }
              }}
              data-testid="uploader-image"
            />
            {uploading && <ProgressBar />}
            <div className="flex gap-4">
              <Button type="outline" onClick={() => open()} disabled={disabled}>
                {t('buttons.change')}
              </Button>
              <Button
                type="outline"
                disabled={disabled}
                onClick={() => {
                  setErrors(undefined);
                  setImgUrl('');
                  imageUrlCB?.('');
                  setErrorAdvert?.({
                    type: 'custom',
                    message: 'Image is required.',
                  });
                  setErrorOffer?.({
                    type: 'custom',
                    message: 'Image is required.',
                  });
                  // eslint-disable-next-line
                  if (isImageBase64) {
                    backImageBase64({ imgBase64: '', imgType: '', imgName: '' });
                  }
                }}
              >
                {t('buttons.remove')}
              </Button>
            </div>
          </>
        ) : (
          <>
            {errors ? (
              <Icon name="image-error" width="40px" />
            ) : (
              <Icon name="default-image" width="40px" />
            )}
            <Button
              type={errors ? 'cancel' : 'outline'}
              onClick={() => open()}
              disabled={disabled}
              data-testid="upload-button"
              loading={uploading}
            >
              {errors ? t('buttons.try-again') : t('buttons.add-image')}
            </Button>
          </>
        )}
        <aside
          className="flex flex-col justify-center items-center gap-2"
          aria-label={`info and criteria for ${imgType} image uploads`}
        >
          {!imgUrl && (
            <>
              <p>{subText}</p>
              <p className="text-md">{criteria}</p>
            </>
          )}
          <div className="flex items-center gap-1">
            <p className="text-errorText text-md">{formError || errors?.message}</p>
            {(formError || errors) && (
              <Icon name="error-exclamation" width="16px" height="16px" />
            )}
          </div>
        </aside>
      </div>
    </div>
  );
};

export default ImageUploader;
