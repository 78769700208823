import { Switch as SwitchToggle } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OfferResponse } from '../../../api';
import getAllFormErrors from '../../../helpers/forms/getAllFormErrors';
import StickyFooter from '../../StickyFooter/StickyFooter';
import Accordion from '../../UI/Accordion/Accordion';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import WrapperFormImage from '../CustomWrapperForm/WrapperFormImage';
import AdOfferForm from '../OfferForm/AdOfferForm/AdOfferForm';
import AppleLogoFormImage from '../PassDetailsForm/AppleLogoFormImage';
import GoogleLogoFormImage from '../PassDetailsForm/GooglePassLogoImage';
import AdFormDetails from './AdFormDetails';
import AdFormImage from './AdFormImage';
import AdFormSchedule from './AdFormSchedule';
import { AdFormSchema } from './AdFormTypes';
import LinkOffersModal from './LinkOffersModal';

interface AddFormProps {
  initialData?: AdFormSchema;
  adIsActive: boolean;
  isSubmitting: boolean;
  onSubmit: SubmitHandler<AdFormSchema>;
  categoryBackArr?: any;
  categoryChildBackArr?: any;
  categoriesCheckArr?: any;
  advertError?: any;
  backCountry?: any;
}

const AdForm = ({
  initialData,
  adIsActive,
  onSubmit,
  isSubmitting,
  advertError,
  categoryBackArr,
  backCountry,
  categoryChildBackArr,
  categoriesCheckArr,
}: AddFormProps) => {
  const { t } = useTranslation('ads');
  const nav = useNavigate();

  const [mode] = useState(initialData !== undefined ? 'edit' : 'create');
  const { brandId } = useParams();
  const [linkOffersModalOpen, setLinkOffersModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [changeCategorySelection, setChangeCategorySelection] = useState(false);
  const [customWrapperEnabled, setCustomWrapperEnabled] = useState(false);

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;

  const currentUpdateTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '20')}`;

  const formMethods = useForm<AdFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialData || {
      imagePath: '',
      googleAdId: '',
      title: '',
      beginDate: currentDate,
      endDate: '',
      beginTime: currentTime,
      endTime: '',
      categories: [],
      followedCategories: [],
      offers: [],
    },
  });
  const {
    trigger,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
    setValue,
    formState: { errors, isDirty },
  } = formMethods;

  const formWatcher = watch();
  const { append: appendOffers } = useFieldArray({
    control,
    name: 'offers',
  });
  const onLinkOffers = (offersAdded: OfferResponse[]) => {
    appendOffers(
      offersAdded?.map(offer => ({
        ...offer,
        beginDate: offer?.startTime?.split('T')[0] || '',
        beginTime: offer?.startTime?.split('T')[1].slice(0, -4) || '',
        endDate: offer?.expiryTime?.split('T')[0] || '',
        endTime: offer?.expiryTime?.split('T')[1].slice(0, -4) || '',
        redemptionType: offer?.redemptionType,
        offerStatus: offer?.offerStatus,
        boosts: offer.boosts?.map(boost => ({
          ...boost,
          id: boost.id || '',
          boostAvailable: true,
          isUltimate: boost.isUltimate,
        })),
        offerCodes: offer.offerCodes,
      }))
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setSaveModalOpen(false);
    }, 500);
  }, [isSubmitting]);

  useEffect(() => {
    console.log('wrapperDetails.wrapperType', getValues('wrapperDetails.wrapperType'));
    if (customWrapperEnabled && getValues('wrapperDetails.wrapperType') === 'DEFAULT') {
      setValue('wrapperDetails.wrapperType', 'CUSTOM');
    }

    // update case
    if (
      customWrapperEnabled === false &&
      getValues('wrapperDetails.wrapperType') === 'CUSTOM' &&
      mode === 'edit'
    ) {
      setCustomWrapperEnabled(true);
    }
    console.log('wrapperDetails.wrapperType', getValues('wrapperDetails.wrapperType'));
  }, [customWrapperEnabled]);

  console.log('data', `passDetails.promotions.${0}.expiryDate`);
  const formatDate = (datetime: string) => {
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="w-full mx-auto flex flex-col gap-6" data-testid="ad-form">
      <div className="flex gap-2">
        <FormProvider {...formMethods}>
          <form className="flex flex-col gap-6 w-full">
            <Accordion
              defaultOpen
              type="advert"
              title={
                <>
                  {/* <h1 className="w-[90px] text-left text-lg">{t('ad')}</h1>{' '} */}
                  {mode === 'edit' ? (
                    <h2
                      className="w-[340px] text-left text-ellipsis"
                      aria-label="ad title"
                    >
                      {getValues('title') ? getValues('title') : 'Ad Title'}
                    </h2>
                  ) : (
                    <span className="w-[340px]" />
                  )}
                  <div className="flex flex-row text-white">
                    <p>{t('form.dates')}</p>
                    <div className="flex flex-row ml-4 text-white gap-2">
                      <p>{getValues('beginDate').split('-').reverse().join('/')}</p>-
                      <p>{getValues('endDate').split('-').reverse().join('/')}</p>
                    </div>
                  </div>
                </>
              }
            >
              <AdFormDetails
                adIsActive={adIsActive}
                getValues={getValues}
                changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
                categoryBackArr={(item: any) => categoryBackArr(item)}
                categoryChildBackArr={(item: any) => categoryChildBackArr(item)}
                categoriesCheckArr={categoriesCheckArr}
              />
              <AdFormSchedule adIsActive={adIsActive} />
              <AdFormImage defaultImage={getValues('imageUrl') || undefined} />
            </Accordion>

            {/* // Custom wrapper */}
            {/* Custom Wrapper Section */}
            <Accordion defaultOpen type="advert" title="Custom Wrapper">
              <div className="flex flex-row justify-between items-center">
                <p>Toggle on to upload your own wrapper</p>
                <SwitchToggle
                  checked={customWrapperEnabled}
                  onChange={() => {
                    setCustomWrapperEnabled(!customWrapperEnabled);
                    setValue(
                      'wrapperDetails.wrapperType',
                      !customWrapperEnabled ? 'CUSTOM' : 'DEFAULT'
                    );
                  }}
                  className={`${customWrapperEnabled ? 'bg-primary' : 'bg-gray-300'}
                    relative inline-flex h-[30px] w-[49px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Enable Custom Wrapper</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      customWrapperEnabled ? 'translate-x-5' : 'translate-x-0'
                    }
                    pointer-events-none inline-block h-[26px] w-[25px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </SwitchToggle>
              </div>

              {customWrapperEnabled && (
                <div className="flex flex-col gap-4 mt-4">
                  {(
                    [
                      {
                        label: 'Overlay',
                        width: 900,
                        height: 750,
                        name: 'overlayImageUrl',
                      },
                      {
                        label: 'Apple Wallet CTA',
                        width: 900,
                        height: 750,
                        name: 'appleWalletImageUrl',
                      },
                      {
                        label: 'Google Wallet CTA',
                        width: 900,
                        height: 750,
                        name: 'googleWalletImageUrl',
                      },
                      { label: 'Banner', width: 90, height: 750, name: 'bannerImageUrl' },
                    ] as const
                  ).map(({ label, width, height, name }) => (
                    <div key={label} className="border p-4 rounded-lg">
                      <p className="font-medium mb-2">{label}</p>
                      <WrapperFormImage
                        defaultImage={
                          getValues(`wrapperDetails.${name}`)
                            ? `${process.env.REACT_APP_GOOGLE_BUCKET}/${getValues(
                                `wrapperDetails.${name}`
                              )}`
                            : undefined
                        }
                        name={name}
                        width={width}
                        height={height}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Accordion>

            {/* Pass Details Section */}
            <Accordion defaultOpen type="advert" title="Pass Details">
              <div className="flex gap-4">
                <div className="flex-1">
                  <p className="text-sm font-semibold text-gray-600 mb-2">
                    Pass Color (i.e :#FFFF)
                  </p>
                  <input
                    type="text"
                    className={`w-full p-3 bg-white border ${
                      errors.passDetails?.passColor ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                    {...formMethods.register('passDetails.passColor', {
                      required: 'Pass Colour is required',
                    })}
                  />
                  {errors.passDetails?.passColor && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.passDetails?.passColor?.message}
                    </p>
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-gray-600 mb-2">
                    Font Color (i.e :#FFFF)
                  </p>
                  <input
                    type="text"
                    className={`w-full p-3 bg-white border ${
                      errors.passDetails?.fontColor ? 'border-red-500' : 'border-gray-300'
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                    {...formMethods.register('passDetails.fontColor', {
                      required: 'Font Colour is required',
                    })}
                  />
                  {errors.passDetails?.fontColor && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.passDetails?.fontColor?.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-4 mt-4">
                <div className="border p-4 rounded-lg shadow-lg bg-gray-50">
                  <p className="font-medium mb-2 text-gray-700">
                    Pass Logo (Google Wallet)
                  </p>
                  <GoogleLogoFormImage
                    defaultImage={
                      getValues('passDetails.googlePassLogo')
                        ? `${process.env.REACT_APP_GOOGLE_BUCKET}/${getValues(
                            'passDetails.googlePassLogo'
                          )}`
                        : undefined
                    }
                    width={300}
                    height={300}
                  />
                </div>
                <div className="border p-4 rounded-lg shadow-lg bg-gray-50">
                  <p className="font-medium mb-2 text-gray-700">
                    Pass Logo (Apple Wallet)
                  </p>
                  <AppleLogoFormImage
                    defaultImage={
                      getValues('passDetails.applePassLogo')
                        ? `${process.env.REACT_APP_GOOGLE_BUCKET}/${getValues(
                            'passDetails.applePassLogo'
                          )}`
                        : undefined
                    }
                    width={234}
                    height={102}
                  />
                </div>
              </div>

              <div className="flex gap-4 mt-4">
                <div className="flex-1">
                  <p className="text-sm font-semibold text-gray-600 mb-2">
                    App Store ID (Provide app store Id of your app)
                  </p>
                  <input
                    type="number"
                    className={`w-full p-3 bg-white border ${
                      errors.passDetails?.appStoreId
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                    placeholder="Enter App Store ID"
                    {...formMethods.register('passDetails.appStoreId')}
                  />
                  {errors.passDetails?.appStoreId && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.passDetails?.appStoreId?.message}
                    </p>
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-gray-600 mb-2">
                    Play Store Url
                  </p>
                  <input
                    type="text"
                    className={`w-full p-3 bg-white border ${
                      errors.passDetails?.playStoreUrl
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                    placeholder="Enter Play Store URL"
                    {...formMethods.register('passDetails.playStoreUrl')}
                  />
                  {errors.passDetails?.playStoreUrl && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.passDetails?.playStoreUrl?.message}
                    </p>
                  )}
                </div>
                <div className="flex-1">
                  <p className="text-sm font-semibold text-gray-600 mb-2">
                    Clickthrough Url
                  </p>
                  <input
                    type="text"
                    className={`w-full p-3 bg-white border ${
                      errors.passDetails?.clickThroughUrl
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                    placeholder="Enter Clickthrough URL"
                    {...formMethods.register('passDetails.clickThroughUrl', {
                      required: 'Clickthrough URL is required',
                    })}
                  />
                  {errors.passDetails?.clickThroughUrl && (
                    <p className="text-xs text-red-500 mt-1">
                      {errors.passDetails?.clickThroughUrl?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Promotions Section */}
              {[0, 1, 2].map(index => (
                <div
                  key={index}
                  className="border p-4 rounded-lg shadow-sm bg-white mt-6"
                >
                  <p className="text-lg font-semibold text-gray-700">
                    Promotion {index}{' '}
                    <span className="text-gray-500 text-sm">(Optional)</span>
                  </p>
                  <div className="mt-2">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        {...formMethods.register(
                          `passDetails.promotions.${index}.isIncluded`
                        )}
                      />
                      <span className="text-gray-600">
                        Check box to include in campaign
                      </span>
                    </label>
                  </div>

                  {/* Conditional rendering for promotion details if checkbox is checked */}
                  {watch(`passDetails.promotions.${index}.isIncluded`) && (
                    <div className="mt-4">
                      <p className="text-sm font-semibold text-gray-600 mb-2">
                        Description
                      </p>
                      <input
                        type="text"
                        className={`w-full p-3 bg-white border ${
                          errors.passDetails?.promotions?.[index]?.description
                            ? 'border-red-500'
                            : 'border-gray-300'
                        } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200`}
                        placeholder="Enter a description of the promotion"
                        {...formMethods.register(
                          `passDetails.promotions.${index}.description`,
                          { required: 'Description is required' }
                        )}
                      />
                      {errors.passDetails?.promotions?.[index]?.description && (
                        <p className="text-xs text-red-500 mt-1">
                          {errors.passDetails?.promotions?.[index]?.description?.message}
                        </p>
                      )}

                      <p className="text-sm font-semibold text-gray-600 mb-2 mt-4">
                        Redemption Code / URL
                      </p>
                      <input
                        type="text"
                        className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                        placeholder="Choose to enter a code or URL"
                        {...formMethods.register(
                          `passDetails.promotions.${index}.redemptionUrl`
                        )}
                      />

                      <p className="text-sm font-semibold text-gray-600 mb-2 mt-4">
                        Expiry Date
                      </p>
                      <input
                        type="date"
                        className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                        {...formMethods.register(
                          `passDetails.promotions.${index}.expiryDate`
                        )}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Accordion>

            <div className="flex flex-row justify-between w-full mt-4">
              <h2 className="font-semibold my-3 text-[24px]">Create Offer</h2>

              <Button
                type="outline"
                onClick={() => {
                  appendOffers({
                    brandId: getValues('brandId'),
                    imageUrl: '',
                    requiredCoins: 1,
                    imagePath: '',
                    title: '',
                    subtitle: '',
                    description: '',
                    enableHalo: false,
                    enableWeightage: false,
                    beginDate: currentDate,
                    beginTime: currentTime,
                    endDate: currentDate,
                    startTime: currentTime,
                    expiryTime: currentUpdateTime,
                    endTime: currentUpdateTime,
                    redemptionType: 'CODE',
                    offerStatus: 'Publish',
                    offerCodes: undefined,
                    boosts: [],
                  });
                }}
              >
                Create Offer
              </Button>
            </div>
            {formWatcher.offers?.length ? (
              formWatcher.offers?.map((offer, index) => {
                const now = new Date();
                const start = offer.startTime ? new Date(offer.startTime) : now;
                let offerIsActive = false;
                if (start < now) {
                  offerIsActive = true;
                }
                return (
                  <AdOfferForm
                    key={index}
                    offer={offer}
                    index={index}
                    backCountry={(e: any) => {
                      setChangeCategorySelection(true);
                      backCountry(e);
                    }}
                    offerIsActive={offerIsActive}
                  />
                );
              })
            ) : (
              <div className="w-full rounded-xl flex flex-row justify-center">
                <p className="mb-3 mt-4 text-textSecondary">No Offer available</p>
              </div>
            )}
          </form>
        </FormProvider>
      </div>

      <Modal
        type="large"
        isOpen={scheduleModalOpen}
        setIsOpen={setScheduleModalOpen}
        height="max-h-[900px]"
      >
        <div className="flex flex-col gap-4 w-full mb-9">
          <h2 className="text-lg font-semibold">Please enter a schedule</h2>
          <p className="text-base">
            You must create a schedule within your Ad in order to link an Offer.
          </p>
        </div>
        <div className="flex justify-end">
          <Button
            type="secondary"
            onClick={() => {
              setScheduleModalOpen(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal>
      <LinkOffersModal
        open={linkOffersModalOpen}
        setOpen={setLinkOffersModalOpen}
        brandId={brandId}
        onLinkOffers={onLinkOffers}
        offersLinkedToAd={getValues('offers') || []}
        startTime={
          getValues(`beginDate`) && getValues(`beginTime`)
            ? `${getValues(`beginDate`)}T${getValues(`beginTime`)}:00Z`
            : undefined
        }
        endTime={
          getValues(`endDate`) && getValues(`endTime`)
            ? `${getValues(`endDate`)}T${getValues(`endTime`)}:00Z`
            : undefined
        }
      />

      <Modal
        type="large"
        isOpen={saveModalOpen}
        setIsOpen={setSaveModalOpen}
        height={Object.keys(errors).length > 0 ? '!h-[400px]' : '!h-[240px]'}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {mode === 'create' ? t('button.create') : t('button.update')}
            </h2>
            <p>{t('form.modal.createA')}</p>
            <p className="mt-4">{t('form.modal.createB')}</p>

            {advertError && <p className="text-secondary">{advertError}</p>}
            {Object.keys(errors).length > 0 && (
              <>
                {getAllFormErrors(errors)?.length > 0 && <p>Please enter valid:</p>}
                <div className="h-[150px] overflow-y-auto">
                  {getAllFormErrors(errors).map((error, i) => (
                    <p key={i} className="text-secondary">
                      • {error}
                    </p>
                  ))}
                  {getAllFormErrors(errors)?.length === 0 && (
                    <p className="text-secondary">• Please fill red marked fields</p>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setSaveModalOpen(false)}>
              {t('button.create-back')}
            </Button>
            <Button
              type="secondary"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length > 0}
            >
              {mode === 'create' ? t('form.create') : t('form.update')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal type="small" isOpen={discardModalOpen} setIsOpen={setDiscardModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              {t('form.modal.cancel-title')}
            </h2>
            <p>{t('form.modal.cancel-info')}</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setDiscardModalOpen(false)}>
              {t('button.cancel-back')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                reset();
                nav(`/ads/${getValues('brandId')}`);
              }}
            >
              {t('button.cancel-proceed')}
            </Button>
          </div>
        </div>
      </Modal>
      {(isDirty || changeCategorySelection) && (
        <StickyFooter
          discard={() => setDiscardModalOpen(true)}
          save={() => {
            trigger();
            // if (!advertError) {
            setSaveModalOpen(true);
            // }
          }}
          brandError={advertError}
          positiveCTAText={mode === 'create' ? t('form.create') : t('form.update')}
        />
      )}
    </div>
  );
};

export default AdForm;
