import { FC, useContext, useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CodeUploadResponse, OfferResponse } from '../../api';
import AdForm from '../../components/Forms/AdForm/AdForm';
import { AdFormSchema } from '../../components/Forms/AdForm/AdFormTypes';
import { OfferFormType } from '../../components/Forms/OfferForm/OfferForm';
import Icon from '../../components/UI/Icon/Icon';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useCreateAd from '../../services/queries/useCreateAd';
import usePostUploadCsv from '../../services/queries/usePostUploadCsv';

const CreateAd: FC = () => {
  const { t } = useTranslation('ads');
  const nav = useNavigate();
  const [categoriesArr, setCategoriesArr] = useState<any>([]);
  const [categoriesChildArr, setCategoriesChildArr] = useState<any>([]);
  const [advertError, setAdvertError] = useState<any>('');
  const [countryList, setCountryList] = useState<any>([]);

  const { brandId } = useParams();
  const { mutateAsync: uploadCsvCodes, isLoading: isUploadCsvLoading } =
    usePostUploadCsv(brandId);

  const { setBrand } = useContext(BrandContext);

  const { mutate: createAd, isLoading: createLoading } = useCreateAd(brandId);
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  useEffect(() => {
    getBrandById().then(res => setBrand(res.data));
  }, []);

  useEffect(() => {
    document.title = brand ? brand?.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  const getUploadCSVPromises = (
    formOffers: OfferFormType[],
    responseOffers: OfferResponse[]
  ) => {
    const csvPromises: Array<Promise<CodeUploadResponse>> = [];
    formOffers?.forEach((offer, index) => {
      const requestTextCheck = `${offer.title}${offer.subtitle}`;
      const responseTextCheck = `${responseOffers?.[index].title}${responseOffers?.[index].subtitle}`;
      const matchedOfferId =
        requestTextCheck === responseTextCheck && offer.id
          ? offer.id
          : responseOffers?.find(o => `${o.title}${o.subtitle}` === requestTextCheck)?.id;
      if (offer.csvFile) {
        csvPromises.push(
          uploadCsvCodes({
            offerId: matchedOfferId || '',
            csv: offer.csvFile,
            mode: 'APPEND',
          })
        );
      }
      offer.boosts?.forEach(boost => {
        if (boost.csvFile) {
          csvPromises.push(
            uploadCsvCodes({
              offerId: matchedOfferId || '',
              boostId: boost.id,
              csv: boost.csvFile,
              mode: 'APPEND',
            })
          );
        }
      });
    });

    return csvPromises;
  };

  const onSubmit: SubmitHandler<AdFormSchema> = async data => {
    // if (!countryList?.length && data.offers?.length) {
    //   setAdvertError("Please select atleast one country");
    // }
    // else
    if (!categoriesArr?.length) {
      setAdvertError('Please select atleast one category');
    }
    // else if (!categoriesChildArr?.length) {
    //   setAdvertError("Please select atleast one subcategory");
    // }
    else {
      createAd(
        {
          title: data.title,
          googleAdId: data.googleAdId,
          imagePath: data.imagePath,
          startTime: `${data.beginDate}T${data.beginTime}:00Z`,
          expiryTime: `${data.endDate}T${data.endTime}:00Z`,
          // categories: categoriesArr,
          categories: [...categoriesArr, ...categoriesChildArr],
          offers: data.offers
            ? data.offers.map(offer => ({
                brandId,
                title: offer.title,
                countries: offer.countries ?? [],
                requiredCoins: Number(offer.requiredCoins),
                enableWeightage: offer.enableWeightage,
                enableHalo: offer.enableHalo,
                subtitle: offer.subtitle,
                description: offer.description,
                imagePath: offer.imagePath,
                redemptionType: offer.redemptionType,
                offerStatus: offer.offerStatus,
                redemptionUrl: offer.redemptionUrl,
                startTime: `${offer.beginDate}T${offer.beginTime}:00Z`,
                expiryTime: `${offer.endDate}T${offer.endTime}:00Z`,
                boosts: offer.boosts?.map(boost => ({
                  ...boost,
                  id: boost.id || '',
                  weightage: boost.weightage ? Number(boost.weightage) : 0,
                  quantity: boost.quantity ? Number(boost.quantity) : 0,
                  boostAvailable: true,
                })),
              }))
            : [],
          wrapperDetails: data.wrapperDetails,
          passDetails: {
            ...data.passDetails,
            appStoreId: Number.parseInt(data.passDetails.appStoreId?.toString(), 10),
          },
        },
        {
          onSuccess: response => {
            if (response.offers && data.offers) {
              // this had to be implemnted cause the offer Id needed to upload the csv file
              Promise.all(getUploadCSVPromises(data.offers, response.offers))
                .then(() => {
                  nav(`/ads/${brandId}`, { replace: true });
                })
                .catch(error => {
                  console.log(error);
                });
            } else {
              nav(`/ads/${brandId}`, { replace: true });
            }
            setAdvertError('');
          },
          onError: (err: any) => {
            setAdvertError(err?.response?.data?.message);
          },
        }
      );
    }
  };

  return (
    <div className="wrapper mb-40">
      <div className="flex w-full justify-start">
        <button
          type="button"
          onClick={() => nav(-1)}
          aria-label="go back"
          data-testid="back-button"
        >
          <Icon name="back-arrow" width="24" />
        </button>
        <h1 className="font-semibold">{t('form.create')}</h1>
      </div>
      <AdForm
        adIsActive={false}
        onSubmit={onSubmit}
        backCountry={
          (e: any) => console.log(e)
          // setCountryList(e?.map((es: any) => es.code))
        }
        advertError={advertError}
        isSubmitting={createLoading || isUploadCsvLoading}
        categoriesCheckArr={categoriesArr}
        categoryChildBackArr={(item: any) => setCategoriesChildArr(item)}
        categoryBackArr={(item: any) => setCategoriesArr(item)}
      />
    </div>
  );
};

export default CreateAd;
