import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { AdvertisementResponse } from '../../api';
import TickleApi, { AddEditAdvertRequest } from '../Services';

const useCreateAd = (id: string | undefined) => {
  const createAd = async (ad: AddEditAdvertRequest) =>
    (await TickleApi.createAd(id || '', ad)).data;

  return useMutation<AdvertisementResponse, AxiosResponse, AddEditAdvertRequest>(
    ['advert', id],
    ad => createAd(ad)
  );
};

export default useCreateAd;
